import React from 'react'

import PageWrapper from 'components/layout/page-wrapper'
import { HeaderSection, TrezorSection } from 'components/pages/ledger'
import pageData from 'data/pages/Contents/ledger.json'

import 'static/assets/scss/styles.scss'
import { AssetsSection } from 'src/components/pages/shared'
import SimpleHead from 'src/components/head/SimpleHead'
import { graphql } from 'gatsby'
import { getImage, withArtDirection } from 'gatsby-plugin-image'

import 'static/ledger/scss/styles.scss'

function LedgerPage({ location, data: imagesData = {} }) {
  const { content, path: pageName } = pageData
  const { header, assets, trezor } = content

  const images = {
    background: withArtDirection(getImage(imagesData.backgroundDesktop), [
      {
        media: '(max-width: 768px)',
        image: getImage(imagesData.backgroundMobile),
      },
    ]),
    preview: withArtDirection(getImage(imagesData.previewDesktop), [
      {
        media: '(max-width: 768px)',
        image: getImage(imagesData.previewMobile),
      },
    ]),
    trezor: getImage(imagesData.trezorBox),
  }

  return (
    <PageWrapper className="x__ledger-page" headerProps={{ location, trackerPage: pageName }}>
      <HeaderSection
        data={{
          ...header,
          backgroundImage: images.background,
          previewImage: images.preview,
        }}
        pageName={pageName}
        location={location}
      />
      {assets && <AssetsSection id="assets" variant="linear" data={assets} />}
      {trezor && <TrezorSection {...trezor} image={images.trezor} />}
    </PageWrapper>
  )
}

export default LedgerPage

export function Head() {
  const { path, title, description, keywords } = pageData

  return <SimpleHead path={path} title={title} description={description} keywords={keywords} />
}

export const query = graphql`
  query {
    backgroundDesktop: file(relativePath: { regex: "/^ledger\/img\/hero-bg-d\\.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }

    backgroundMobile: file(relativePath: { regex: "/^ledger\/img\/hero-bg-m\\.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }

    previewDesktop: file(relativePath: { regex: "/^ledger\/img\/hero-preview-d\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(width: 560, placeholder: BLURRED)
      }
    }

    previewMobile: file(relativePath: { regex: "/^ledger\/img\/hero-preview-m\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(width: 240, placeholder: BLURRED)
      }
    }

    trezorBox: file(relativePath: { regex: "/^ledger\/img\/trezor-box\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(width: 260, placeholder: BLURRED)
      }
    }
  }
`
