import React from 'react'
import HeroSection from 'components/pages/shared/hero-section'
import LedgerPreview from './parts/ledger-preview'
import HeroActions from './parts/hero-actions'

import './scss/header-section.scss'

function HeaderSection({ location, pageName, data }) {
  const { backgroundImage, previewImage } = data

  return (
    <HeroSection
      location={location}
      pageName={pageName}
      data={data}
      backgroundImage={backgroundImage}
      customActions={<HeroActions location={location} pageName={pageName} />}
      subtitleClassName="x__ledger-page__hero__subtitle"
      limitBackgroundMobileHeight
    >
      {!!previewImage && <LedgerPreview image={previewImage} />}
    </HeroSection>
  )
}

export default HeaderSection
