import React from 'react'
import './hero-actions.scss'
import { buildDownloadButtonProps, DownloadButton } from 'src/components/button/download-v2'
import Link from 'src/components/link'
import { useIsMobileDevice } from 'src/js/utils/hooks'

function HeroActions({ location, pageName }) {
  const { isMobile, loading } = useIsMobileDevice()

  const downloadProps = buildDownloadButtonProps({
    location,
    pageName,
    forceShowDownloadPageButton: !isMobile,
    alternativeLinks: [
      {
        text: 'Compatible with ',
      },
      {
        text: 'Ledger Nano X',
        href: 'https://shop.ledger.com/#category-cryptocurrency-wallets-nanos',
        target: '_blank',
      },
      {
        text: ' and ',
      },
      {
        text: 'Ledger Flex',
        href: 'https://shop.ledger.com/pages/ledger-flex',
        target: '_blank',
      },
    ],
  })

  if (loading) return null

  return (
    <div className="x__ledger-page__hero__actions">
      <DownloadButton {...downloadProps} />
      <Link
        href="https://www.exodus.com/support/en/articles/9811448-getting-started-with-exodus-and-ledger"
        target="_blank"
        className="x__ledger-page__hero__actions__secondary-action"
      >
        Learn More
      </Link>
    </div>
  )
}

export default HeroActions
