import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import './scss/trezor-section.scss'
import Button from 'components/button'
import { SectionHeading } from 'src/components/pages/shared'

function TrezorSection({ image, h2, h3, cta }) {
  return (
    <div className="x__ledger-page__trezor-section">
      <div className="x__ledger-page__trezor-section__media">
        {image && (
          <GatsbyImage
            className="x__ledger-page__trezor-section__media__image"
            image={image}
            alt="Trezor wallet"
            loading="lazy"
          />
        )}
      </div>

      <div className="x__ledger-page__trezor-section__content">
        <SectionHeading h2={h2} h3={h3} />
        {!!cta.path && !!cta.copy && (
          <Button to={cta.path} copy={cta.copy} target="_blank" size="largest" />
        )}
      </div>
    </div>
  )
}

export default TrezorSection
