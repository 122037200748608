import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import './ledger-preview.scss'

function LedgerPreview({ image }) {
  if (!image) return null

  return (
    <div className="x__ledger-page__preview">
      <div className="x__ledger-page__preview__content">
        <GatsbyImage image={image} alt="Ledger + Exodus preview" loading="eager" />
      </div>
    </div>
  )
}

export default LedgerPreview
